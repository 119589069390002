var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-text-field', {
    attrs: {
      "label": "Timer min*",
      "type": "number",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.hoursMin,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "hoursMin", $$v);
      },
      expression: "curriculumValues.hoursMin"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "Timer max*",
      "type": "number",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.hoursMax,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "hoursMax", $$v);
      },
      expression: "curriculumValues.hoursMax"
    }
  }), _vm.isOnlineHoursRequired ? _c('v-text-field', {
    class: {
      required: _vm.isOnlineHoursRequired
    },
    attrs: {
      "label": "Timer online",
      "type": "number",
      "rules": _vm.isOnlineHoursRequired ? _vm.validateNotEmpty : []
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.hoursOnline,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "hoursOnline", $$v);
      },
      expression: "curriculumValues.hoursOnline"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "label": "Total studiebelastning",
      "type": "number"
    },
    on: {
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.hoursStudyLoad,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "hoursStudyLoad", $$v);
      },
      expression: "curriculumValues.hoursStudyLoad"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }