var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-autocomplete', {
    attrs: {
      "label": "Hovedområde*",
      "dense": "",
      "value": +_vm.$route.query.mainCourseId,
      "disabled": "",
      "items": _vm.defaultOptions.courseAreas,
      "item-text": function itemText(item) {
        return item.code + ' - ' + item.description;
      },
      "item-value": "id",
      "rules": _vm.validateNotEmpty
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Kursområde*",
      "dense": "",
      "value": +_vm.$route.query.subCourseId,
      "items": _vm.courseAreaItems,
      "item-text": function itemText(item) {
        return item.code + ' - ' + item.description;
      },
      "item-value": "id",
      "disabled": "",
      "rules": _vm.validateNotEmpty
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Undervisningsform*",
      "dense": "",
      "value": _vm.$route.query.teachingMethod,
      "items": _vm.defaultOptions.teachingMethods,
      "disabled": "",
      "rules": _vm.validateNotEmpty
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "SSB-kode *",
      "dense": "",
      "value": +_vm.$route.query.subCourseId,
      "items": _vm.courseAreaItems,
      "item-text": "ssbCode",
      "item-value": "id",
      "disabled": "",
      "rules": _vm.validateNotEmpty
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }