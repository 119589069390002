
import { api } from "@/api/api";
import { ApiGetCurriculumSubjectCodeDto, ApiGetCustomerDto, ApiGetNusCodeDto } from "@/api/generated/Api";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useRoute, useStore } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { StoreState } from "@/store/store.state.interface";
import { PropType, computed, defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CurriculumSpecific",
  props: {
    value: {
      type: Object as PropType<UpsertCurriculumDto>,
      required: true,
    },
    nusCodes: {
      type: Array as PropType<ApiGetNusCodeDto[]>,
      required: true,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const route = useRoute();
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");
    const subjectCodes = ref<ApiGetCurriculumSubjectCodeDto[]>([]);

    const getSubjectCodes = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        subjectCodes.value = (await api.curriculum.getCurriculumSubjectCodes()).data;
      });
    };

    onMounted(() => {
      getSubjectCodes();
    });

    const isMainCourseVocationalSchool = isVocationalSchool(+route.query.mainCourseId);

    const organizationItem = (organization: ApiGetCustomerDto) =>
      `${organization.customer?.name} - ${organization.customer?.orgNumber}`;

    const nusCodeItem = (nusCode: ApiGetNusCodeDto) => `${nusCode.code} - ${nusCode.name}`;

    const emitChanges = () => {
      emit("input", deepCloneObject(curriculumValues.value));
    };

    const addSubjectCode = (value: any, rank: any) => {
      if (curriculumValues.value.subjectCodes?.map((x) => x.rank).includes(rank)) {
        curriculumValues.value.subjectCodes[rank - 1] = { id: value, rank };

        emitChanges();
        return;
      }

      curriculumValues.value.subjectCodes?.push({ id: value, rank });

      emitChanges();
    };

    const setArrayLength = (value: any) => {
      if (curriculumValues.value.subjectCodes) {
        curriculumValues.value.subjectCodes.length = value;
      }

      emitChanges();
    };

    return {
      curriculumValues,
      organizationItem,
      validateNotEmpty,
      emitChanges,
      defaultOptions: computed(() => store.state.plans.defaultOptions),
      contactOrganizations: computed(() =>
        store.state.contacts.organisations.filter((organisation) => organisation.customer.isActive)
      ),
      nusCodeItem,
      isMainCourseVocationalSchool,
      subjectCodes,
      addSubjectCode,
      setArrayLength,
    };
  },
});
