var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormElement', {
    attrs: {
      "inputFieldType": "textField",
      "label": "Navn*",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "changeEvent": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.name,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "name", $$v);
      },
      expression: "curriculumValues.name"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "label": "Medlemsorganisasjon",
      "items": _vm.defaultOptions.memberOrganisations,
      "item-text": function itemText(item) {
        return item.id + ' - ' + item.description;
      },
      "item-value": "id",
      "clearable": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.memberOrgId,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "memberOrgId", $$v);
      },
      expression: "curriculumValues.memberOrgId"
    }
  }), _vm.$route.params.id ? _c('v-autocomplete', {
    attrs: {
      "label": "Status",
      "items": _vm.defaultOptions.courseStatuses,
      "item-text": "description",
      "item-value": "id",
      "disabled": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.statusId,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "statusId", $$v);
      },
      expression: "curriculumValues.statusId"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }