
import FormElement from "@/components/shared/form/FormElement.vue";
import { useAutoDeepCloneProp } from "@/fragments/course/useAutoDeepCloneProp";
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { useStore } from "@/shared/useHelpers";
import { UpsertCurriculumDto } from "@/store/modules/plans/interfaces/UpsertCurriculumDto.interface";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "Name",
  components: { FormElement },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore<StoreState>();
    const curriculumValues = useAutoDeepCloneProp<UpsertCurriculumDto>(props, "value");

    const emitChanges = () => {
      emit("input", deepCloneObject(curriculumValues.value));
    };

    return {
      curriculumValues,
      validateNotEmpty,
      emitChanges,
      defaultOptions: computed(() => store.state.plans.defaultOptions),
    };
  },
});
