var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-form', {
    ref: "form"
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "".concat(_vm.isVocationalSchool ? 'Utdanningen ' : 'Kurset ').concat(_vm.curriculumValues.foreknowledgeRequired ? 'har krav til forkunnskaper (fyll inn under) *' : 'har ingen krav til forkunnskaper *'),
      "color": "primary"
    },
    model: {
      value: _vm.curriculumValues.foreknowledgeRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "foreknowledgeRequired", $$v);
      },
      expression: "curriculumValues.foreknowledgeRequired"
    }
  }), _vm.curriculumValues.foreknowledgeRequired ? _c('CurriculumSection', {
    attrs: {
      "title": "Forkunskaper *"
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": "Forkunskaper som kreves for dette kurset ...",
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "required": ""
    },
    model: {
      value: _vm.curriculumValues.foreknowledgeRequirements,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "foreknowledgeRequirements", $$v);
      },
      expression: "curriculumValues.foreknowledgeRequirements"
    }
  })], 1) : _vm._e(), _c('CurriculumSection', {
    attrs: {
      "title": "Målgruppe*"
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": "Målgruppe ...",
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "required": ""
    },
    model: {
      value: _vm.curriculumValues.targetGroup,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "targetGroup", $$v);
      },
      expression: "curriculumValues.targetGroup"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }