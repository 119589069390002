var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('Name', {
    staticClass: "section-container",
    model: {
      value: _vm.curriculumValues,
      callback: function callback($$v) {
        _vm.curriculumValues = $$v;
      },
      expression: "curriculumValues"
    }
  }), +_vm.$route.query.mainCourseId && _vm.isNew ? _c('CurriculumInfo', {
    staticClass: "section-container"
  }) : _vm._e(), _vm.isMainCourseLanguage || _vm.isVocationalSchool ? _c('OptionalInformation', {
    staticClass: "section-container",
    model: {
      value: _vm.curriculumValues,
      callback: function callback($$v) {
        _vm.curriculumValues = $$v;
      },
      expression: "curriculumValues"
    }
  }) : _vm._e(), _c('Hours', {
    staticClass: "section-container",
    model: {
      value: _vm.curriculumValues,
      callback: function callback($$v) {
        _vm.curriculumValues = $$v;
      },
      expression: "curriculumValues"
    }
  }), _c('CurriculumSpecific', {
    attrs: {
      "nusCodes": _vm.nusCodes,
      "isDraft": _vm.isDraft
    },
    model: {
      value: _vm.curriculumValues,
      callback: function callback($$v) {
        _vm.curriculumValues = $$v;
      },
      expression: "curriculumValues"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }