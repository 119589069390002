var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseStepper', {
    staticClass: "mt-3",
    attrs: {
      "stepNames": _vm.stepNames,
      "isPost": true,
      "postBtn": "Opprett gjennomføringsplan"
    },
    on: {
      "cancel": _vm.cancel,
      "post": _vm.post
    },
    scopedSlots: _vm._u([{
      key: "step_1",
      fn: function fn() {
        return [_c('GeneralInfoStep')];
      },
      proxy: true
    }, {
      key: "step_2",
      fn: function fn() {
        return [_c('LearningRequirementsStep')];
      },
      proxy: true
    }, {
      key: "step_3",
      fn: function fn() {
        return [_c('TargetAudienceStep')];
      },
      proxy: true
    }, {
      key: "step_4",
      fn: function fn() {
        return [_c('LearningObjectivesStep')];
      },
      proxy: true
    }, {
      key: "step_5",
      fn: function fn() {
        return [_c('CourseDescriptionStep')];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }