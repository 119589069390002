var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-stepper', {
    staticClass: "elevation-0",
    attrs: {
      "rounded": "lg"
    },
    model: {
      value: _vm.currentStep,
      callback: function callback($$v) {
        _vm.currentStep = $$v;
      },
      expression: "currentStep"
    }
  }, [_c('v-stepper-header', {
    staticClass: "elevation-0"
  }, [_vm._l(_vm.steps, function (stepId) {
    return [_c('v-stepper-step', {
      key: "".concat(stepId, "-step"),
      attrs: {
        "complete": _vm.currentStep > stepId,
        "step": stepId
      }
    }, [_vm._v(" " + _vm._s(_vm.stepNames[stepId - 1]) + " ")]), stepId !== _vm.steps ? _c('v-divider', {
      key: stepId
    }) : _vm._e()];
  })], 2), _c('v-stepper-items', _vm._l(_vm.steps, function (stepId) {
    return _c('v-stepper-content', {
      key: "".concat(stepId, "-content"),
      attrs: {
        "step": stepId
      }
    }, [_c('div', {
      staticStyle: {
        "min-height": "66vh",
        "transform": "translateX(0%)"
      }
    }, [_vm._t(_vm.getSlotName(stepId - 1))], 2), _c('v-container', {
      attrs: {
        "fluid": ""
      }
    }, [_c('v-row', [stepId !== 1 ? _c('v-btn', {
      attrs: {
        "text": ""
      },
      on: {
        "click": function click($event) {
          return _vm.previouStep(stepId);
        }
      }
    }, [_vm._v(" Forrige ")]) : _vm._e(), stepId !== _vm.steps ? _c('v-btn', {
      attrs: {
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.nextStep(stepId);
        }
      }
    }, [_vm._v(" Neste ")]) : stepId.step === _vm.steps.length && _vm.isPost ? _c('v-btn', {
      attrs: {
        "color": "primary",
        "loading": _vm.loading
      },
      on: {
        "click": _vm.postAction
      }
    }, [_vm._v(" " + _vm._s(_vm.postBtn) + " ")]) : _vm._e(), _c('v-spacer'), _vm.isDraft ? _c('v-btn', {
      attrs: {
        "loading": _vm.loading,
        "color": "primary"
      },
      on: {
        "click": _vm.draftAction
      }
    }, [_vm._v(_vm._s(_vm.draftBtn))]) : _vm._e(), _c('v-btn', {
      attrs: {
        "text": ""
      },
      on: {
        "click": _vm.cancelStepper
      }
    }, [_vm._v("Avbryt")])], 1)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }