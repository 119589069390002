var render = function () {
  var _vm$curriculumValues$, _vm$curriculumValues$2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "section-container"
  }, [_c('v-select', {
    attrs: {
      "label": "Nivå*",
      "items": _vm.defaultOptions.courseLevels,
      "item-text": "description",
      "item-value": "id",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.levelId,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "levelId", $$v);
      },
      expression: "curriculumValues.levelId"
    }
  }), _c('v-select', {
    attrs: {
      "label": "Evalueringskode *",
      "items": _vm.defaultOptions.courseEvaluations,
      "item-text": "description",
      "item-value": "id",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.evaluationId,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "evaluationId", $$v);
      },
      expression: "curriculumValues.evaluationId"
    }
  })], 1), _c('v-autocomplete', {
    attrs: {
      "label": "Samarbeidspartnere",
      "items": _vm.contactOrganizations,
      "chips": "",
      "dense": "",
      "deletable-chips": "",
      "multiple": "",
      "item-text": _vm.organizationItem,
      "item-value": "id"
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.partners,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "partners", $$v);
      },
      expression: "curriculumValues.partners"
    }
  }), _vm.isMainCourseVocationalSchool ? _c('v-select', {
    attrs: {
      "label": "NUS-kode *",
      "items": _vm.nusCodes,
      "item-text": _vm.nusCodeItem,
      "item-value": "code",
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.nusCode,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "nusCode", $$v);
      },
      expression: "curriculumValues.nusCode"
    }
  }) : _vm._e(), _vm.isMainCourseVocationalSchool ? _c('v-select', {
    attrs: {
      "label": "Antall emner*",
      "items": [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.setArrayLength
    },
    model: {
      value: _vm.curriculumValues.vocationalSchool.subjectCount,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues.vocationalSchool, "subjectCount", $$v);
      },
      expression: "curriculumValues.vocationalSchool.subjectCount"
    }
  }) : _vm._e(), _vm.isDraft ? _c('div', _vm._l((_vm$curriculumValues$ = _vm.curriculumValues.vocationalSchool) === null || _vm$curriculumValues$ === void 0 ? void 0 : _vm$curriculumValues$.subjectCount, function (index) {
    return _c('v-select', {
      key: index,
      attrs: {
        "item-text": "subjectName",
        "item-value": "id",
        "items": _vm.subjectCodes,
        "label": "Emne ".concat(index)
      },
      on: {
        "change": function change($event) {
          return _vm.addSubjectCode($event, index);
        }
      },
      model: {
        value: _vm.curriculumValues.copySubjectCodes[index - 1],
        callback: function callback($$v) {
          _vm.$set(_vm.curriculumValues.copySubjectCodes, index - 1, $$v);
        },
        expression: "curriculumValues.copySubjectCodes[index - 1]"
      }
    });
  }), 1) : _vm._e(), !_vm.isDraft ? _c('div', _vm._l((_vm$curriculumValues$2 = _vm.curriculumValues.vocationalSchool) === null || _vm$curriculumValues$2 === void 0 ? void 0 : _vm$curriculumValues$2.subjectCount, function (index) {
    return _c('v-select', {
      key: index,
      attrs: {
        "item-text": "subjectName",
        "item-value": "id",
        "items": _vm.subjectCodes,
        "label": "Emne ".concat(index)
      },
      on: {
        "change": function change($event) {
          return _vm.addSubjectCode($event, index);
        }
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }