var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "form"
  }, [_c('v-text-field', {
    attrs: {
      "label": "Lenke til ekstern studieplan"
    },
    model: {
      value: _vm.curriculumValues.externalCurriculumUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "externalCurriculumUrl", $$v);
      },
      expression: "curriculumValues.externalCurriculumUrl"
    }
  }), _c('CurriculumSection', {
    attrs: {
      "title": _vm.isVocationalSchool ? 'Læringsutbytte*' : 'Læringsmål*'
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": "Mer informasjon ... *",
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions
    },
    model: {
      value: _vm.curriculumValues.learningObjectives,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "learningObjectives", $$v);
      },
      expression: "curriculumValues.learningObjectives"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }