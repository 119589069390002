
// TODO better filename!
import { isVocationalSchool } from "@/shared/helpers/curriculumHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "CurriculumSection",
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const store = useStore<StoreState>();

    return {
      isVocationalSchool: computed(() => isVocationalSchool(store.state.courses.curriculum.mainCourseId)),
      getDefaultOptions: computed(() => store.state.plans.defaultOptions),
      study: computed(() => store.state.plans.studyplan),
    };
  },
});
