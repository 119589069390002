var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "".concat(_vm.isVocationalSchool ? 'Utdanningen ' : 'Kurset ').concat(_vm.curriculumValues.teacherRequired ? 'skal ha en lærer *' : 'skal ikke ha en lærer *'),
      "color": "primary"
    },
    model: {
      value: _vm.curriculumValues.teacherRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "teacherRequired", $$v);
      },
      expression: "curriculumValues.teacherRequired"
    }
  }), _vm.curriculumValues.teacherRequired ? _c('CurriculumSection', {
    attrs: {
      "title": "Lærerkrav"
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "placeholder": "Lærerkrav ...",
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "required": ""
    },
    model: {
      value: _vm.curriculumValues.teacherRequirements,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "teacherRequirements", $$v);
      },
      expression: "curriculumValues.teacherRequirements"
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }