var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-form', {
    ref: "form"
  }, [_c('v-switch', {
    attrs: {
      "inset": "",
      "label": "".concat(_vm.curriculumValues.courseCertificateRequired ? 'Krav om Kursbevis eller Vitnemål *' : 'Ingen krav om Kursbevis eller Vitnemål *'),
      "color": "primary"
    },
    model: {
      value: _vm.curriculumValues.courseCertificateRequired,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "courseCertificateRequired", $$v);
      },
      expression: "curriculumValues.courseCertificateRequired"
    }
  }), _c('CurriculumSection', {
    attrs: {
      "title": "Innhold*"
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "placeholder": "Innhold *"
    },
    model: {
      value: _vm.curriculumValues.course.content,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues.course, "content", $$v);
      },
      expression: "curriculumValues.course.content"
    }
  })], 1), _c('CurriculumSection', {
    attrs: {
      "title": "Metode*"
    }
  }, [_c('tiptap-vuetify', {
    attrs: {
      "card-props": {
        outlined: true
      },
      "extensions": _vm.extensions,
      "placeholder": "Metode *"
    },
    model: {
      value: _vm.curriculumValues.course.method,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues.course, "method", $$v);
      },
      expression: "curriculumValues.course.method"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }