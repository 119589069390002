var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isMainCourseLanguage ? _c('v-autocomplete', {
    attrs: {
      "label": "CEFR*",
      "items": _vm.defaultOptions.cefrLevels,
      "rules": _vm.validateNotEmpty
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.cefrLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues, "cefrLevel", $$v);
      },
      expression: "curriculumValues.cefrLevel"
    }
  }) : _vm._e(), _vm.isMainCourseVocationalSchool ? _c('v-switch', {
    attrs: {
      "label": "".concat(_vm.curriculumValues.vocationalSchool.practice ? 'Praksis *' : 'Ikke praksis *'),
      "rules": _vm.validateBoolean,
      "inset": ""
    },
    on: {
      "change": _vm.emitChanges
    },
    model: {
      value: _vm.curriculumValues.vocationalSchool.practice,
      callback: function callback($$v) {
        _vm.$set(_vm.curriculumValues.vocationalSchool, "practice", $$v);
      },
      expression: "curriculumValues.vocationalSchool.practice"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }