var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.title ? [_c('div', {
    staticClass: "font-weight-light"
  }, [_vm._v(_vm._s(_vm.title))])] : _vm._e(), _c('div', {
    staticClass: "section-container"
  }, [_vm._t("default", null, null, {
    study: _vm.study,
    isVocationalSchool: _vm.isVocationalSchool,
    getDefaultOptions: _vm.getDefaultOptions
  })], 2)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }